import React, { useContext, useEffect } from 'react';
import BlockContentToReact from '@sanity/block-content-to-react';
import GatsbyImage from 'gatsby-image';
import { GlobalState } from '../../store';
import { graphql } from 'gatsby';

import {
  Wrapper,
  List,
  Item,
  Info,
  Title,
  Description,
  Thumbnail,
  InnerWrapper,
} from '../../styles/events';

const EventsPage = ({ data, pageContext }) => {
  const globalStateDispatch = useContext(GlobalState.Dispatch);

  const {
    allSanityEvent: { edges },
  } = data;

  const events = edges.map(({ node }, index) => ({
    ...node,
    number: `${edges.length - index}`.padStart(
      Math.max(2, `${edges.length}`.length),
      '0'
    ),
  }));

  useEffect(() => {
    globalStateDispatch({
      type: 'setTheme',
      theme: {
        color: '#000',
        background: '#ccc',
      },
    });
  }, [globalStateDispatch]);

  return (
    <Wrapper>
      <List>
        {events.map(({ id, title, number, image, description, isArchived }) => (
          <Item key={id} archived={isArchived}>
            <InnerWrapper>
              <Info>
                <Title>
                  {number}. {title}
                </Title>
                <Description>
                  <BlockContentToReact blocks={description} />
                </Description>
              </Info>
              <Thumbnail>
                <GatsbyImage fluid={image.asset.fluid} />
              </Thumbnail>
            </InnerWrapper>
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};

export default EventsPage;

export const EventsQuery = graphql`
  query Events {
    allSanityEvent(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          description: _rawDescription
          isArchived
        }
      }
    }
  }
`;
