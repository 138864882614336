import styled, { css } from 'styled-components';
import { mediaQueries } from '../theme';

export const Wrapper = styled.article``;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 60px 0;
`;

export const InnerWrapper = styled.div`
  display: flex;
  filter: grayscale(0%);
  transition: filter 0.3s ease-in-out, opacity 0.3s ease-in-out;

  ${mediaQueries.COMPACT} {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
`;

export const Item = styled.li`
  border-bottom: solid 2px;

  margin: 30px 0 0 0;
  padding: 0 0 30px 0;

  &::first-child {
    margin-top: 0;
  }

  ${props =>
    props.archived &&
    css`
      ${InnerWrapper} {
        filter: grayscale(100%);
        opacity: 0.3;
      }

      &:hover {
        ${InnerWrapper} {
          filter: grayscale(0%);
          opacity: 1;
        }
      }
    `}
`;

export const Info = styled.div`
  padding: 0 15px 0 0;
  width: 50%;

  ${mediaQueries.COMPACT} {
    padding: 15px 0 0 0;
    width: 100%;
  }

  a {
    position: relative;
    text-decoration: none;

    &:after {
      border-bottom: 2px solid;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
    }
  }
`;

export const Thumbnail = styled.figure`
  margin: 0;
  padding: 0 0 0 15px;
  width: 50%;

  ${mediaQueries.COMPACT} {
    padding: 0 0 15px 0;
    width: 100%;
  }
`;

export const Image = styled.img`
  height: auto;
  width: 100%;
`;

export const Title = styled.h1``;

export const Description = styled.div``;
